import {Button, Collapse, Input} from "antd";
import Link from "next/link";
import React, {useState} from "react";
import {toast} from "react-hot-toast";
const {Panel} = Collapse;
import {generateCSRFToken} from "@/utils/csrf";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const csrfToken = generateCSRFToken();
  const subscribeToMailchimp = async (e: any) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const formData = new FormData();
      formData.append("_csrf", csrfToken);
      const res = await fetch("/api/subscribe", {
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });
      const result = await res.json();
      if (result.status !== 200) {
        throw new Error(result.message);
      }
      setEmail("");
      setIsLoading(false);
      toast.success(result.message);
    } catch (error: any) {
      toast.error(error.message || "Failed to send email");
      setIsLoading(false);
    }
  };
  return (
    <>
      <section className="bg-blue-base">
        <section className="flex flex-col justify-between gap-10 py-16 text-white lg:flex-row wrapper">
          {/* about */}
          <section className="order-2 hidden md:block lg:order-1">
            <h4 className="m-0 text-sm font-normal tracking-wider text-white uppercase lg:text-sm">About WARDAH</h4>
            <p className="max-w-[330px] mt-2 mb-0 leading-6 text-white">
              Wardah is a beauty brand that cares and understands the wish of every women to always feel calm and
              comfortable with their look.
            </p>
          </section>
          {/* HELP links */}
          <section className="order-3 hidden md:block lg:order-2">
            <h4 className="m-0 text-sm font-normal tracking-wider text-white uppercase lg:text-sm">HELP</h4>
            <ul className="flex flex-col mt-2 mb-0 space-y-1 ">
              <li>
                <Link className="text-white" href="/how-to-order">
                  How To Order
                </Link>
              </li>
              <li>
                <Link className="text-white" href="/shipping-and-delivery">
                  Shipping and Delivery
                </Link>
              </li>
              <li>
                <Link className="text-white" href="/order-status">
                  Order Status
                </Link>
              </li>
            </ul>
          </section>
          {/* quick links */}
          <section className="order-3 hidden md:block lg:order-2">
            <h4 className="m-0 text-sm font-normal tracking-wider text-white uppercase lg:text-sm">QUICK LINKS</h4>
            <ul className="flex flex-col mt-2 mb-0 space-y-1 ">
              <li>
                <Link className="text-white" href="/#halal">
                  Halal Green Beauty
                </Link>
              </li>
              <li>
                <Link className="text-white" href="/#experience">
                  Make Up Expert
                </Link>
              </li>
              <li>
                <Link className="text-white" href="/inspiration">
                  Inspiring Movement
                </Link>
              </li>
              <li>
                <Link className="text-white" href="/philosophy">
                  Philosophy
                </Link>
              </li>
            </ul>
          </section>
          {/* newsletter */}
          <section className="w-full lg:max-w-[345px] order-1 lg:order-3">
            <h4 className="m-0 text-sm font-normal tracking-wider text-white uppercase lg:text-sm">
              SUBSCRIBE NEWSLETTER
            </h4>
            <div className="w-full mt-4">
              <form onSubmit={subscribeToMailchimp} className="flex">
                <input type="hidden" name="_csrf" value={csrfToken} />
                <Input
                  size="large"
                  className="bg-white border-white placeholder:font-light"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button loading={isLoading} htmlType="submit" className="shadow-none" size="large" type="primary">
                  SUBMIT
                </Button>
              </form>
            </div>
            <div className="hidden mt-6 lg:block">
              <h4 className="m-0 text-sm font-normal tracking-wider text-white uppercase lg:text-sm">
                CONNECT WITH US
              </h4>
              <div className="flex mt-2 space-x-3 opacity-60">
                <a href="https://www.facebook.com/wardahbeauty.my" target="_blank" rel="noreferrer">
                  <img src="/icons/footer/fb.svg" alt="fb-logo" />
                </a>
                <a href="https://twitter.com/WardahBeauty_MY" target="_blank" rel="noreferrer">
                  <img src="/icons/footer/twitter.svg" alt="twitter-logo" />
                </a>
                <a href="https://www.instagram.com/wardahbeauty_my/?hl=en" target="_blank" rel="noreferrer">
                  <img src="/icons/footer/instagram.svg" alt="instagram-logo" />
                </a>
                <a href="https://www.youtube.com/channel/UCSmmmmDJpjqlI6XgsIcUQoA" target="_blank" rel="noreferrer">
                  <img src="/icons/footer/youtube.svg" alt="youtube-logo" />
                </a>
                {/* <a href="/" target="_blank" rel="noreferrer">
                  <img src="/icons/footer/wa.svg" alt="wa-logo" />
                </a> */}
              </div>
            </div>
          </section>
          {/* mobile */}
          <section className="order-2 md:hidden">
            <Collapse className="collapse-footer" expandIconPosition={"end"} defaultActiveKey={["1", "2", "3"]} ghost>
              <Panel header="ABOUT" key="1">
                <p className="text-white">
                  Wardah is a local cosmetic for any woman without any limit or restrictions. Wardah has been in the
                  world of cosmetics and skin care for a dozen years combining heritage and innovate with new
                  technology.
                </p>
              </Panel>
              <Panel header="HELP" key="2">
                <ul className="flex flex-col mb-0 space-y-1 ">
                  <li>
                    <Link className="text-white" href="/how-to-order">
                      How To Order
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" href="/shipping-and-delivery">
                      Shipping and Delivery
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" href="/order-status">
                      Order Status
                    </Link>
                  </li>
                </ul>
              </Panel>
              <Panel header="QUICK LINKS" key="3">
                <ul className="flex flex-col mb-0 space-y-1 ">
                  <li>
                    <Link className="text-white" href="/">
                      Halal Green Beauty
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" href="/">
                      Make Up Expert
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" href="/inspiration">
                      Inspiring Movement
                    </Link>
                  </li>
                  <li>
                    <Link className="text-white" href="/philosophy">
                      Philosophy
                    </Link>
                  </li>
                </ul>
              </Panel>
              <Panel header="CONNECT WITH US" key="4">
                <div className="flex space-x-3 opacity-60">
                  <a href="/">
                    <img src="/icons/footer/fb.svg" alt="fb-logo" />
                  </a>
                  <a href="/">
                    <img src="/icons/footer/twitter.svg" alt="twitter-logo" />
                  </a>
                  <a href="/">
                    <img src="/icons/footer/instagram.svg" alt="instagram-logo" />
                  </a>
                  <a href="/">
                    <img src="/icons/footer/youtube.svg" alt="youtube-logo" />
                  </a>
                  <a href="/">
                    <img src="/icons/footer/wa.svg" alt="wa-logo" />
                  </a>
                </div>
              </Panel>
            </Collapse>
          </section>
        </section>
      </section>
      {/* footer bottom */}
      <section className="text-[10px] text-white bg-blue-dark">
        <div className="flex flex-col-reverse justify-between gap-3 py-4 text-[10px] md:flex-row wrapper md:py-4">
          <span>© 2023 Wardah. All rights reserved.</span>
          <span>
            <a className="tracking-widest text-white" href="/faq">
              FAQ
            </a>
            <span className="px-1">|</span>
            <a className="tracking-widest text-white" href="/privacy-policy">
              PRIVACY POLICY
            </a>
          </span>
        </div>
      </section>
    </>
  );
};
export default Footer;
