// Hooks
import React, {useRef, useState, useEffect, FormEvent} from "react";

// Components
import Link from "next/link";
import classNames from "classnames";
import {Button, Drawer, Input, Popover} from "antd";

// Utils & Libs
import SearchMobileSidebar from "../SearchMobileSidebar";
import {UseQueryResult, useQuery} from "react-query";
import {toast} from "react-hot-toast";
import getID from "@/utils/getID";
import {useSession} from "next-auth/react";
import {useRouter} from "next/router";
import {IProductCategoryParent} from "@/types/product.types";
import {generateCSRFToken} from "@/utils/csrf";
import userCartStore from "@/store/cartStore";
import ApiService from "@/libs/ApiServices";
import CartSideBar from "../Cart/CartSidebar";

interface IHeaderMenuRoute {
  name: string;
  route: string | null;
}

interface IHeader extends IProductCategoryParent {
  child_header: IProductCategoryParent[];
}

interface IHeaderMenuSubMenu extends IHeaderMenuRoute {
  menu: IHeaderMenuRoute[] | [];
}

interface IHeaderMenu extends IHeaderMenuRoute {
  styleType: number;
  subMenu: IHeaderMenuSubMenu[];
}

// static
let header_data: IHeaderMenu[] = [
  {
    name: "Experience",
    route: null,
    styleType: 2,
    subMenu: [
      // {name: "BEAUTY MATCH", route: "/makeup", menu: []},
      // {name: "SHADE MATCH", route: "/shade-finder", menu: []},
      {name: "SKIN LAB", route: "/advanced-skinlab", menu: []},
    ],
  },
  {
    name: "Inspiration",
    route: "/inspiration",
    styleType: 0,
    subMenu: [],
  },
  {
    name: "PHILOSOPHY",
    route: "/philosophy",
    styleType: 0,
    subMenu: [],
  },
];

// const AccountSideBar: React.FC<{open: boolean; setOpen: React.Dispatch<boolean>}> = ({open, setOpen}) => {
//   const {data: userSessionData} = useSession();

//   return (
//     <Drawer
//       open={open}
//       onClose={() => setOpen(false)}
//       title={null}
//       closeIcon={null}
//       closable={false}
//       placement="right"
//       className="p-0 m-0 account-sidebar"
//       bodyStyle={{padding: 0}}
//       width={"100%"}
//     >
//       {/* nav & profile */}
//       <section className="px-6 py-8 text-white md:py-10 bg-blue-base">
//         {/* nav */}
//         <section className="flex justify-between">
//           <p className="m-0">MY ACCOUNT</p>
//           <button onClick={() => setOpen(false)} className="bg-transparent">
//             X
//           </button>
//         </section>
//         {/* profile */}
//         <section className="flex items-center mt-5 space-x-5">
//           <div className="overflow-hidden bg-white rounded-full h-14 w-14 md:w-20 md:h-20">
//             <img
//               className="object-cover w-full h-full"
//               src={userSessionData?.user.image ? userSessionData?.user.image : "/icons/default-profile-image.svg"}
//               alt="profile picture"
//             />
//           </div>
//           <span className="text-xl">{userSessionData?.user.name}</span>
//         </section>
//       </section>

//       {/* menu */}
//       <section className="px-6 mt-10">
//         <ul className="flex flex-col space-y-8">
//           <li>
//             <Link className="font-medium tracking-wider hover:underline" href="/account/profile/setting">
//               ACCOUNT SETTING
//             </Link>
//           </li>
//           <li>
//             <Link className="font-medium tracking-wider hover:underline" href="/account/profile/beauty-profile">
//               BEAUTY PROFILE
//             </Link>
//           </li>
//           <li>
//             <button
//               onClick={() => signOut()}
//               className="font-medium tracking-wider bg-transparent hover:underline text-red-base"
//             >
//               LOG OUT
//             </button>
//           </li>
//         </ul>
//       </section>
//     </Drawer>
//   );
// };

const Header = () => {
  const router = useRouter();
  const headerRef = useRef<HTMLElement>(null);
  const csrfToken = generateCSRFToken();

  const {getUserCart} = userCartStore((prev) => prev);
  const [query, setQuery] = useState("");

  // sidebar
  const [showMobileSearchSidebar, setShowMobileSearchSidebar] = useState(false);
  const [showMobileMenuSidebar, setShowMobileMenuSidebar] = useState(false);
  const [showMobileMenuSidebarData, setShowMobileMenuSidebarData] = useState(false);
  const [showCartSidebar, setShowCartSidebar] = useState(false);
  const [mobileSidebarData, setMobileSidebarData] = useState<IHeaderMenuSubMenu>();

  const {status: userSessionStatus} = useSession();
  const {carts} = userCartStore();

  const {data: headerMenuData}: UseQueryResult<IHeaderMenu[]> = useQuery("category", async () => getCategory(), {
    initialData: [],
  });

  const {data: topText} = useQuery(["top-text"], async () => await getTopText());

  const getCategory = async () => {
    try {
      const response = await ApiService.get("/category/header");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      let result: IHeader = response.data.data;

      let temp_arr: any = [];

      // init for header parent
      temp_arr.push({name: result.name, route: "/product/" + result.slug, menu: []});
      for (let i = 0; i < result.child_header.length; i++) {
        temp_arr[0].menu.push({
          name: result.child_header[i].name,
          route: "/product/" + result.slug + "?category=" + result.child_header[i].slug,
        });
      }

      for (let i = 0; i < result.child_header.length; i++) {
        let temp: any = {name: result.child_header[i].name, route: "/product/" + result.child_header[i].slug, menu: []};
        for (let j = 0; j < result.child_header[i].child_categories.length; j++) {
          if (result.child_header[i].child_categories[j].tag === 0) {
            temp.menu.push({
              name: result.child_header[i].child_categories[j].name,
              route:
                "/product/" +
                result.child_header[i].slug +
                "?category=" +
                result.child_header[i].child_categories[j].slug,
            });
          }
        }
        temp_arr.push(temp);
      }

      const merge_with_static_menu = [
        {
          name: "Products",
          route: null,
          styleType: 1,
          subMenu: [...temp_arr],
        },
        ...header_data,
      ];

      return merge_with_static_menu;
    } catch (error) {
      toast.error("Failed to get product category");
      console.log(error);
    }
  };

  const getTopText = async () => {
    try {
      const response = await ApiService.get("/toptext");

      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchQuery = (e: FormEvent) => {
    e.preventDefault();
    // Include the CSRF token in the form data
    const formData = new FormData();
    formData.append("_csrf", csrfToken); // Use the appropriate field name

    if (!query.length) {
      return;
    }

    window.location.href = "/search?q=" + query;
  };

  // always close sidebar when route change
  useEffect(() => {
    const handleRouteChange = () => {
      setShowMobileMenuSidebar(false);
      setShowMobileSearchSidebar(false);
      setShowMobileMenuSidebarData(false);
      setShowCartSidebar(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  // fetch usercart
  useEffect(() => {
    getUserCart();
  }, [userSessionStatus]);

  return (
    <>
      <header
        ref={headerRef}
        id="header-container"
        className={classNames("sticky top-0 z-[997] w-full shadow-sm  transition-all navbar  bg-[#FFFFFF] ")}
      >
        {/* promo */}
        {topText?.length ? (
          <div className="text-white bg-blue-base">
            <p className="py-2 mb-0 wrapper top-text dont-reset" dangerouslySetInnerHTML={{__html: topText || ""}}></p>
          </div>
        ) : null}

        {/* actual navbar */}
        <section className="flex justify-between gap-10 py-3 lg:py-5 wrapper">
          {/* left */}
          <section className="flex gap-6 lg:gap-9">
            {/* mobile burger */}
            <button onClick={() => setShowMobileMenuSidebar((prev) => !prev)} className="bg-transparent lg:hidden">
              <img src="/icons/menu.svg" alt="wardah-menu" />
            </button>

            {/* logo */}
            <Link className="flex items-center " href="/">
              <img className="h-4 lg:h-6" src="/icons/logo.png" alt="wardah-logo" />
            </Link>

            {/* search bar */}
            <form
              onSubmit={handleSearchQuery}
              className="relative hidden lg:w-[240px] lg:flex 2xl:w-[320px] items-center "
            >
              <Input
                onChange={(e) => setQuery(e.target.value)}
                value={query}
                className="w-full"
                placeholder="What are you looking for?"
              />
              <input type="hidden" name="_csrf" value={csrfToken} />
              <button className="absolute bg-transparent right-2">
                <img src="/icons/search-icon.svg" alt="search-icon" />
              </button>
            </form>
          </section>

          {/* right */}
          <ul className="items-center hidden m-0 space-x-6 font-medium uppercase 2xl:space-x-10 justify-evenly lg:flex">
            {headerMenuData?.map((p_menu) => (
              <li key={getID()} className="cursor-pointer">
                {p_menu.subMenu.length ? (
                  <Popover
                    getPopupContainer={() => document.getElementById("header-container") as HTMLElement}
                    placement="bottom"
                    className="w-full"
                    overlayClassName="desktop-menu-popover shadow-md"
                    showArrow={false}
                    align={{offset: [0, 13]}}
                    content={() => (
                      <div
                        className={classNames(
                          {
                            "flex justify-evenly": p_menu.styleType === 1,
                          },
                          "wrapper-sm  gap-5 min-h-[200px] pt-5 pb-10 "
                        )}
                      >
                        {p_menu.subMenu.map((s_menu) => (
                          <div key={getID()} className="">
                            {s_menu.route ? (
                              <a href={s_menu.route}>
                                <h5 className="font-medium uppercase text-blue-dark">{s_menu.name}</h5>
                              </a>
                            ) : (
                              <h5 className="font-medium uppercase text-blue-dark">{s_menu.name}</h5>
                            )}
                            <div className="flex flex-col m-0 mt-5 space-y-3">
                              {s_menu.menu.map((c_menu) => (
                                <div key={getID()}>
                                  {c_menu.route ? (
                                    <a
                                      className="capitalize hover:text-blue-dark hover:underline"
                                      href={c_menu.route || ""}
                                    >
                                      {c_menu.name}
                                    </a>
                                  ) : (
                                    <a
                                      className="capitalize hover:text-blue-dark hover:underline"
                                      href={s_menu.route || ""}
                                    >
                                      {s_menu.name}
                                    </a>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    <span>{p_menu.name}</span>
                  </Popover>
                ) : (
                  <div className="cursor-pointer">
                    <Link href={p_menu.route || ""}>{p_menu.name}</Link>
                  </div>
                )}
              </li>
            ))}

            <li className="relative cursor-pointer min-w-fit" onClick={() => setShowCartSidebar((prev) => !prev)}>
              <img src="/icons/cart-icon.svg" alt="account-icons" />
              <div className="absolute flex items-center justify-center w-4 h-4 rounded-full -right-1 -top-1 bg-blue-base">
                <span className="text-[8px] text-white">{carts.length}</span>
              </div>
            </li>
            <li
              className="cursor-pointer min-w-fit"
              onClick={() =>
                userSessionStatus === "authenticated" ? router.push("/account/profile") : router.push("/sign-in")
              }
            >
              <img src="/icons/account-icon.svg" alt="account-icons" />
            </li>
          </ul>

          {/* only on mobile */}
          <div className="flex gap-3 lg:hidden">
            <Popover
              showArrow={false}
              overlayClassName="search-popover shadow-md"
              getPopupContainer={() => document.getElementById("header-container") as HTMLElement}
              content={() => (
                <form onSubmit={handleSearchQuery} className="py-3 border-t wrapper ">
                  <Input
                    onChange={(e) => setQuery(e.target.value)}
                    value={query}
                    bordered={false}
                    className="p-0"
                    placeholder="What are you looking for?"
                  />
                </form>
              )}
              placement="bottom"
              trigger="click"
            >
              <Button className="p-0 border-none w-fit" type="ghost">
                <img className="" height={27} width={27} src="/icons/search-icon.svg" alt="account-icons" />
              </Button>
            </Popover>

            <button
              className="relative bg-transparent cursor-pointer min-w-fit"
              onClick={() => setShowCartSidebar((prev) => !prev)}
            >
              <img src="/icons/cart-icon.svg" alt="account-icons" />
            </button>
            <button
              className="bg-transparent cursor-pointer min-w-fit"
              onClick={() =>
                userSessionStatus === "authenticated" ? router.push("/account/profile") : router.push("/sign-in")
              }
            >
              <img src="/icons/account-icon.svg" alt="account-icons" />
            </button>
          </div>
        </section>
      </header>

      {/* sidebar mobile menu */}
      <Drawer
        open={showMobileMenuSidebar}
        onClose={() => setShowMobileMenuSidebar((prev) => !prev)}
        title={null}
        closeIcon={null}
        closable={false}
        width={"100%"}
        placement="left"
      >
        <section className="flex justify-end mt-2 mb-4">
          <button
            className="bg-transparent"
            onClick={() => {
              setShowMobileMenuSidebar((prev) => !prev);
            }}
          >
            <img className="w-5" src="/icons/close-mobile-sidebar.svg" alt="close-icon" />
          </button>
        </section>
        <section className="flex flex-col space-y-7">
          {headerMenuData?.map((p_menu, i) => (
            <div key={getID()}>
              {p_menu.subMenu.length ? (
                <div className="flex flex-col space-y-7">
                  {/* just line */}
                  <div
                    className={classNames(
                      {
                        hidden: i === 0,
                      },
                      "w-full h-2 mt-2 border-b border-gray-base"
                    )}
                  ></div>
                  {/* end line */}
                  {p_menu.subMenu[0].menu.length ? (
                    p_menu.subMenu.map((s_menu) => (
                      <div key={getID()}>
                        {s_menu.menu.length ? (
                          <div
                            onClick={() => {
                              setMobileSidebarData(s_menu);
                              setShowMobileMenuSidebarData(true);
                              setShowMobileMenuSidebar(false);
                            }}
                            className="flex items-end justify-between w-full"
                          >
                            <span className="font-medium uppercase text-blue-dark">{s_menu.name}</span>
                            <img className="w-5" src="/icons/arrow-right-sidebar.svg" alt="arrow-right-icon" />
                          </div>
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <div
                      onClick={() => {
                        setMobileSidebarData({name: p_menu.name, route: p_menu.route, menu: p_menu.subMenu});
                        setShowMobileMenuSidebarData(true);
                        setShowMobileMenuSidebar(false);
                      }}
                      className="flex items-end justify-between w-full"
                    >
                      <span className="font-medium uppercase text-blue-dark">{p_menu.name}</span>
                      <img className="w-5" src="/icons/arrow-right-sidebar.svg" alt="arrow-right-icon" />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <Link href={p_menu.route || ""} className="font-medium uppercase text-blue-dark">
                    {p_menu.name}
                  </Link>
                </div>
              )}
            </div>
          ))}
        </section>
      </Drawer>

      {/* sidebar category */}
      <Drawer
        open={showMobileMenuSidebarData}
        onClose={() => setShowMobileMenuSidebarData((prev) => !prev)}
        title={null}
        closeIcon={null}
        closable={false}
        width={"100%"}
        placement="right"
      >
        <section className="flex justify-between mt-2 mb-10">
          <button className="bg-transparent">
            <img
              className="w-5"
              onClick={() => {
                setShowMobileMenuSidebar((prev) => !prev);
                setShowMobileMenuSidebarData((prev) => !prev);
              }}
              src="/icons/arrow-left-sidebar.svg"
              alt="arrow-left-icon"
            />
          </button>

          <button
            className="bg-transparent"
            onClick={() => {
              setShowMobileMenuSidebarData((prev) => !prev);
            }}
          >
            <img className="w-5" src="/icons/close-mobile-sidebar.svg" alt="close-icon" />
          </button>
        </section>
        <section className="">
          {mobileSidebarData?.route ? (
            <Link href={mobileSidebarData?.route || ""}>
              <h5 className="uppercase text-blue-dark">{mobileSidebarData?.name}</h5>
            </Link>
          ) : (
            <h5 className="uppercase text-blue-dark">{mobileSidebarData?.name}</h5>
          )}
          <div className="flex flex-col mt-8 space-y-7">
            {mobileSidebarData?.menu.map((cat) => (
              <a key={getID()} className="capitalize hover:text-blue-dark hover:underline" href={cat.route || ""}>
                {cat.name}
              </a>
            ))}
          </div>
        </section>
      </Drawer>

      {/* cart sidebar */}
      <CartSideBar open={showCartSidebar} setOpen={setShowCartSidebar} />

      {/* sidebar mobile search */}
      <SearchMobileSidebar show={showMobileSearchSidebar} setShow={setShowMobileSearchSidebar} />
    </>
  );
};

export default Header;
