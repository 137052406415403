// Hooks
import React, {useEffect, useState} from "react";
import userCartStore from "@/store/cartStore";

// Components
import {Button} from "antd";

// Utils & Libs
import classNames from "classnames";
import PriceFormatter from "@/utils/PriceFormatter";

// Types
import {ICartItem} from "@/types/product.types";

const CartProductItem: React.FC<{data: ICartItem}> = ({data}) => {
  const hasVariant = !!data.variant_combinations;
  const productQuantity = hasVariant ? data.variant_combinations?.qty : data.product.qty;
  const isSoldOut = data.qty > (productQuantity || 0);

  const {isLoading, removeUserCart, addUpdateUserCart} = userCartStore();

  const [price, setPrice] = useState({price: 0, promo: 0});
  const [isDiscount, setIsDiscount] = useState(false);

  const qtyHandler = (type: "increase" | "decrease") => {
    if (isLoading) {
      return;
    }

    let product_qty = 0;

    if (type === "decrease") {
      if (isSoldOut) {
        product_qty = productQuantity || 1;
      } else {
        product_qty = data.qty - 1;
      }
    }

    if (type === "increase") {
      product_qty = data.qty + 1;
    }

    let payload: any = {
      cart_id: data.id,
      product_id: data.product.id,
      qty: product_qty,
    };

    if (data.variant_combinations) {
      payload.product_variant_combination_id = data.product_variant_combination_id;
    }

    addUpdateUserCart(payload);
  };

  const QuantityButton = () => {
    return (
      <div
        className={classNames(
          {"grayscale text-gray-base": isSoldOut},
          "flex items-center justify-between flex-1 h-10 px-2 border border-blue-base"
        )}
      >
        <Button onClick={() => qtyHandler("decrease")} size="large" type="ghost" className="border-none">
          <img src="/icons/min-blue.svg" alt="" />
        </Button>
        <div className="flex items-center justify-normal">
          <span className="block w-full text-center">{data.qty}</span>
        </div>
        <Button onClick={() => qtyHandler("increase")} size="large" type="ghost" className="border-none">
          <img src="/icons/plus-blue.svg" alt="" />
        </Button>
      </div>
    );
  };

  const ProductPrice = () => {
    return (
      <div className="">
        {isDiscount ? (
          <div className="flex items-center gap-2">
            <span className="font-semibold text-red-base">{PriceFormatter(price.promo)}</span>
            <span className="text-xs font-semibold line-through text-gray-base">{PriceFormatter(price.price)}</span>
          </div>
        ) : (
          <span className="inline-block font-bold text-end">{PriceFormatter(price.price)}</span>
        )}
      </div>
    );
  };

  useEffect(() => {
    // product dont have variant and dont have promo
    if (data.variant_combinations) {
      setPrice({price: +data.variant_combinations.price, promo: +data.variant_combinations.price_promo});
    }

    if (!data.variant_combinations) {
      setPrice({price: +data.product.price, promo: +data.product.price_promo});
    }

    // product dont have vatiant has discount
    if (
      !data.variant_combinations &&
      +data.product.price_promo > 0 &&
      +data.product.price_promo < +data.product.price
    ) {
      setPrice({price: +data.product.price, promo: +data.product.price_promo});
      setIsDiscount(true);
    }

    // product has variant dan variant has discount
    if (
      data.variant_combinations &&
      +data?.variant_combinations?.price_promo > 0 &&
      +data?.variant_combinations?.price_promo < +data?.variant_combinations?.price
    ) {
      setPrice({price: +data.variant_combinations.price, promo: +data.variant_combinations.price_promo});
      setIsDiscount(true);
    }
  }, [data]);

  return (
    <div className="flex pb-4 space-x-4 border-b">
      <div className="w-[180px] ">
        {data.product.images?.length ? (
          <img className="object-contain aspect-square" src={data.product?.images[0]?.url} alt="" />
        ) : null}
      </div>
      <div className="flex flex-col w-full space-y-1">
        <p className="mb-0">{data.product.name}</p>
        {/* if has variant */}
        {data.variant_combinations ? (
          <p className="text-xs text-gray-base">
            {data.variant_combinations?.variant_detail_one?.name}{" "}
            {data.variant_combinations?.variant_detail_two ? (
              <span>, {data.variant_combinations?.variant_detail_two?.name}</span>
            ) : null}
          </p>
        ) : null}

        {/* <span className="inline-block text-gray-base">Qty : {data.qty}</span> */}
        <ProductPrice />
        <div className="h-[40px] max-w-[110px]">
          <QuantityButton />
        </div>
        {isSoldOut ? <p className="mt-auto mb-0 text-red-dark">Out of Stock</p> : null}
      </div>

      <div className="flex flex-col items-end min-w-fit">
        <Button onClick={() => removeUserCart({ids: [data.id]})} className="p-0 border-none w-fit h-fit">
          <img src="/icons/remove-icon.svg" alt="" />
        </Button>

        <div className={classNames({"text-gray-base": isSoldOut}, "mt-auto")}>
          {isDiscount ? (
            <p className="inline-block text-end">{PriceFormatter(price.promo * data.qty)}</p>
          ) : (
            <p className="inline-block text-end">{PriceFormatter(price.price * data.qty)}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartProductItem;
